export default function GCPAudits() {
  return (
    <>
      {/* Hero Section */}
      <section className="flex flex-col items-center bg-gradient-to-r from-[#69D7C1] to-[#336DBC] text-white py-16 px-5 md:px-20">
        <div className="flex flex-col md:flex-row items-center justify-center gap-10">
          <div className="flex flex-col gap-5 md:w-1/2">
            <h1 className="text-4xl md:text-5xl font-extrabold leading-snug">
              Ensuring Quality in Clinical Trials
            </h1>
            <p className="text-lg leading-relaxed">
              A well-organized system audit identifies potential issues early, allowing timely solutions. PharmaVigilence Auditors leverage
              extensive subject matter expertise to achieve audit objectives in complex regulatory environments.
            </p>
          </div>
          <div className="flex justify-center items-center gap-3 relative md:w-1/2">
            <img
              className="rounded-tl-[4rem] w-[48%] h-auto shadow-md"
              src="/services_img_one.webp"
              alt="service_img_one"
            />
            <img
              className="rounded-br-[4rem] w-[48%] h-auto shadow-md"
              src="/services_img_two.webp"
              alt="service_img_two"
            />
          </div>
        </div>
      </section>

      {/* Core Services Section */}
      <section className="bg-gray-50 py-16 px-5 md:px-20">
        <h2 className="text-center text-3xl font-bold text-[#336DBC] mb-10 underline decoration-[3px] decoration-[#69D7C1] underline-offset-8">
          Core Services
        </h2>
        <div className="flex flex-col md:flex-row justify-between gap-10">
          <div className="flex flex-col items-center bg-white shadow-lg rounded-lg p-6 gap-6 w-full md:w-1/3">
            <img
              className="w-16 h-16 object-contain"
              src="/clinical_research.svg"
              alt="Clinical Research"
            />
            <h3 className="text-xl font-semibold text-[#336DBC]">Clinical Research Organization</h3>
            <ul className="list-disc pl-5 text-gray-600">
              <li>System & Facility Audit</li>
              <li>GAP Audit</li>
              <li>For Cause Investigation</li>
              <li>Site Qualification Audit</li>
              <li>Regulatory Compliance Audit</li>
            </ul>
          </div>

          <div className="flex flex-col items-center bg-white shadow-lg rounded-lg p-6 gap-6 w-full md:w-1/3">
            <img
              className="w-16 h-16 object-contain"
              src="/audits.png"
              alt="Audits"
            />
            <h3 className="text-xl font-semibold text-[#336DBC]">Comprehensive Audits</h3>
            <ul className="list-disc pl-5 text-gray-600">
              <li>Pre-clinical Facility</li>
              <li>Clinical Monitoring</li>
              <li>Bioanalytical Monitoring</li>
              <li>PK Stats Monitoring</li>
            </ul>
          </div>

          <div className="flex flex-col items-center bg-white shadow-lg rounded-lg p-6 gap-6 w-full md:w-1/3">
            <img
              className="w-16 h-16 object-contain"
              src="/bioanalytical.png"
              alt="Bioanalytical"
            />
            <h3 className="text-xl font-semibold text-[#336DBC]">Bioanalytical Monitoring</h3>
            <ul className="list-disc pl-5 text-gray-600">
              <li>In-Process Monitoring</li>
              <li>Retrospective Monitoring</li>
              <li>Method Development</li>
              <li>Validation Monitoring</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Detailed Information Section */}
      <MonitoringClinicalComponent />
      <BioanalyticalPhase />
      <PKStats />
    </>
  );
}

function MonitoringClinicalComponent() {
  return (
    <section className="bg-gray-50 py-16 px-5 md:px-20">
      <h2 className="text-center text-3xl font-bold text-[#336DBC] mb-10 underline decoration-[3px] decoration-[#69D7C1] underline-offset-8">
        Monitoring - Clinical Phase
      </h2>
      <div className="flex flex-col gap-6 text-gray-600">
        <p>
          Ensuring the quality of data collected in clinical trials and BA/BE projects is crucial for success. A well-organized system
          audit identifies potential issues early, allowing timely solutions.
        </p>
        <div className="bg-white shadow-md rounded-lg p-6">
          <h3 className="text-xl font-semibold text-[#336DBC]">Site Initiation Visit</h3>
          <ul className="list-disc pl-5">
            <li>Proactively evaluate and address errors in protocols, informed consent forms, and CRFs</li>
            <li>Check site readiness to initiate the project</li>
            <li>Resolution of safety issues and assessment of staff competence</li>
          </ul>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6">
          <h3 className="text-xl font-semibold text-[#336DBC]">Site Monitoring Visit</h3>
          <ul className="list-disc pl-5">
            <li>Original and transparent view of trials</li>
            <li>Timely updates on enrolment, dosing, and safety events</li>
            <li>Complete accountability of investigational product</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

function BioanalyticalPhase() {
  return (
    <section className="bg-white py-16 px-5 md:px-20">
      <h2 className="text-center text-3xl font-bold text-[#336DBC] mb-10 underline decoration-[3px] decoration-[#69D7C1] underline-offset-8">
        Bioanalytical Phase
      </h2>
      <div className="flex flex-col gap-6 text-gray-600">
        <p>
          Delivering excellence in Bioanalytical Monitoring Services with experienced auditors ensuring accuracy and compliance in the
          following areas:
        </p>
        <ul className="list-disc pl-5">
          <li>In-Process Monitoring</li>
          <li>Method Development and Validation</li>
          <li>Retrospective Bioanalytical Monitoring</li>
        </ul>
      </div>
    </section>
  );
}

function PKStats() {
  return (
    <section className="bg-gray-50 py-16 px-5 md:px-20">
      <h2 className="text-center text-3xl font-bold text-[#336DBC] mb-10 underline decoration-[3px] decoration-[#69D7C1] underline-offset-8">
        PK-Stats Monitoring
      </h2>
      <div className="flex flex-col gap-6 text-gray-600">
        <p>
          Pharmacokinetics and statistical monitoring represent innovative concepts in clinical research. Our experts enhance reporting
          quality and accuracy through online and retrospective PK-Stat monitoring.
        </p>
      </div>
    </section>
  );
}
