//import { Helmet } from "react-helmet";

export default function ContactUs() {
  return (
    <>
      {/*<Helmet>
        <title>Contact Us - PharmaVigilence</title>
        <meta
          name="description"
          content="Get in touch with PharmaVigilence for inquiries about our clinical audit services. Contact us today to learn more about our offerings."
        />
        <meta
          name="keywords"
          content="Contact PharmaVigilence, clinical audit services, healthcare compliance, inquiries"
        />
        <meta property="og:title" content="Contact Us - PharmaVigilence" />
        <meta
          property="og:description"
          content="Reach out to PharmaVigilence for questions and support regarding our clinical audit services and healthcare compliance solutions."
        />
        <meta property="og:url" content="https://pharmavigilence.com/contact" />
        <meta property="og:image" content="/contact_us_image.png" />
      </Helmet>*/}

      <section className="flex px-7 py-16 bg-white text-gray-800">
        <div className="container mx-auto flex flex-col md:flex-row items-stretch gap-10">
          {/* Contact Us Info Section */}
          <div className="flex flex-col gap-8 bg-white rounded-lg  p-10 md:w-1/2">
            <header className="flex flex-col gap-4">
              <h3 className="font-poppins text-xl font-semibold text-[#336DBC] underline decoration-[3px] decoration-[#69D7C1] underline-offset-8">
                Contact Us
              </h3>
              <h1 className="text-4xl md:text-5xl font-poppins font-bold">
                Get in Touch with Us
              </h1>
            </header>
            <p className="font-poppins text-lg text-gray-600 leading-relaxed">
              Have questions or need more information about our clinical audit
              services? We're here to help. Reach out to us through any of the
              methods below, and our team will be happy to assist you.
            </p>
            <img
              src="/9233778_4111242.jpg"
              alt="Contact illustration"
              className="w-full rounded-lg"
            />
          </div>

          {/* Contact Form Section */}
          <ContactForm />
        </div>
      </section>
    </>
  );
}

function ContactForm() {
  return (
    <div className="bg-[#E8F5F3] rounded-lg shadow-lg p-10 md:w-1/2 flex flex-col gap-8">
      <h6 className="text-[#336DBC] font-semibold text-sm">
        * Required field
      </h6>
      <form className="flex flex-col gap-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label className="font-semibold text-gray-600">Full Name *</label>
            <input
              type="text"
              className="border border-[#D7D7D7] rounded-md p-3 focus:ring-2 focus:ring-[#336DBC] outline-none"
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold text-gray-600">Mail ID *</label>
            <input
              type="email"
              className="border border-[#D7D7D7] rounded-md p-3 focus:ring-2 focus:ring-[#336DBC] outline-none"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label className="font-semibold text-gray-600">Phone *</label>
            <input
              type="text"
              className="border border-[#D7D7D7] rounded-md p-3 focus:ring-2 focus:ring-[#336DBC] outline-none"
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold text-gray-600">Website</label>
            <input
              type="url"
              className="border border-[#D7D7D7] rounded-md p-3 focus:ring-2 focus:ring-[#336DBC] outline-none"
            />
          </div>
        </div>
        <div className="flex flex-col">
          <label className="font-semibold text-gray-600">Message</label>
          <textarea
            rows={4}
            className="border border-[#D7D7D7] rounded-md p-3 focus:ring-2 focus:ring-[#336DBC] outline-none"
          ></textarea>
        </div>
        <div className="flex items-start gap-3">
          <input type="checkbox" className="mt-1.5 focus:ring-[#336DBC]" />
          <p className="text-sm text-gray-600 leading-relaxed">
            Sign me up to receive updates on our latest audit services, industry
            insights, and upcoming events.
          </p>
        </div>
        <p className="text-sm text-gray-600 leading-relaxed">
          By submitting this form, I authorize PharmaVigilence to contact me
          regarding this inquiry or according to my choice to register for
          future communications. See our Privacy Policy for more details or
          opt-out at any point using the unsubscribe link on any of our emails.
        </p>
        <button
          type="submit"
          className="self-center bg-[#336DBC] text-white rounded-full px-10 py-3 font-semibold shadow-md hover:bg-[#285a9c] transition"
        >
          Submit
        </button>
      </form>
    </div>
  );
}
