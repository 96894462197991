import { Link } from "react-router-dom";

export default function FooterComponent() {
  return (
    <footer className="bg-[#E8F5F3] text-gray-800 py-10">
      {/* Top Section */}
      <div className="container mx-auto flex flex-col md:flex-row items-center md:items-start justify-between px-5 md:px-10 gap-10">
        {/* Logo and Address */}
        <div className="flex flex-col items-center md:items-start gap-3 w-full md:w-1/3">
          <img
            className="w-28 h-auto"
            src="/KPV-LOGO.png"
            alt="Pharma Vigilence Logo"
          />
          <p className="text-sm text-center md:text-left">
            Flat.No.40, 1st Floor, Block No.03, MIG, KPHB 5th Phase, Hyderabad -
            500072.
          </p>
        </div>

        {/* Navigation Links */}
        <ul className="flex flex-wrap justify-center md:justify-start gap-6 font-poppins w-full md:w-1/3">
          <li>
            <Link
              to="/"
              className="text-sm hover:text-[#336DBC] transition"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/services"
              className="text-sm hover:text-[#336DBC] transition"
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/about-us"
              className="text-sm hover:text-[#336DBC] transition"
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="text-sm hover:text-[#336DBC] transition"
            >
              Contact
            </Link>
          </li>
        </ul>

        {/* Social Media Links */}
        <div className="flex flex-col items-center md:items-end gap-3 w-full md:w-1/3">
          <p className="font-semibold">Follow us</p>
          <ul className="flex gap-4">
            <li>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                <img className="w-6 h-6" src="/twitter.svg" alt="Twitter" />
              </a>
            </li>
            <li>
              <a href="https://linkedin.com/company/" target="_blank" rel="noopener noreferrer">
                <img className="w-6 h-6" src="/linkedin.svg" alt="LinkedIn" />
              </a>
            </li>
            <li>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                <img className="w-6 h-6" src="/facebook.svg" alt="Facebook" />
              </a>
            </li>
            <li>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <img className="w-6 h-6" src="/instagram.svg" alt="Instagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Divider */}
      <div className="mt-8 border-t border-gray-300"></div>

      {/* Bottom Section */}
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-5 md:px-10 mt-5 text-sm text-gray-600">
        <p>&copy; 2025 Pharma Vigilence, All Rights Reserved.</p>
        <p>
          Designed with ❤️ by{" "}
          <a
            href="https://vcreatehub.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-[#336DBC] transition"
          >
            VCreateHub
          </a>
        </p>
      </div>
    </footer>
  );
}
