import ContactUs from './contactForm';
import MissionAndVision from './missionAndVision';
import OurServices from './ourServices';
import AboutUs from './aboutUs';
import { Helmet } from 'react-helmet';

export default function HomePageComponent() {
  return (
    <>
      <Helmet>
        <title>Home - PharmaVigilence</title>
        <meta
          name="description"
          content="Welcome to PharmaVigilence. Explore our services and offerings for compliance and auditing."
        />
        <meta
          name="keywords"
          content="home, PharmaVigilence, audits, compliance, quality assurance"
        />
        <meta property="og:title" content="Home - PharmaVigilence" />
        <meta
          property="og:description"
          content="Explore PharmaVigilence's offerings for compliance and auditing."
        />
        <meta property="og:url" content="https://pharmavigilence.com/" />
        <meta property="og:image" content="/KPV-LOGO.png" />
      </Helmet>

      {/* Hero Section */}
      <section className="relative bg-[#fdfefe ] text-gray-800 pb-16 mt-0">
        <div className="container mx-auto flex flex-col-reverse md:flex-row items-center gap-10 px-5 md:px-20">
          <div className="md:w-1/2 space-y-6">
            <h6 className="text-[#336DBC] font-semibold text-lg uppercase tracking-wider">
              Comprehensive Medical Audits
            </h6>
            <h1 className="text-4xl md:text-5xl font-extrabold text-gray-900 leading-snug">
              Ensuring Excellence in Healthcare Auditing
            </h1>
            <p className="text-lg text-gray-600 leading-relaxed">
              We specialize in delivering precise and thorough medical audit services that uphold the highest standards of healthcare.
              Our expert team ensures your practice adheres to regulatory requirements, minimizes risks, and improves operational
              efficiency. Let us help you achieve excellence in patient care and compliance.
            </p>
            <button className="bg-[#336DBC] text-white rounded-lg px-8 py-3 font-semibold shadow-md hover:bg-[#285a9c] transition">
              Know More
            </button>
          </div>
          <div className="md:w-1/2">
            <img
              src="/home_img.svg"
              alt="Healthcare Excellence"
              className="w-full rounded-lg "
            />
          </div>
        </div>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2">
          <img src="/scroll_down.svg" alt="Scroll Down" className="w-10 animate-bounce" />
        </div>
      </section>

      {/* Our Services */}
      <OurServices />

      {/* About Us Section */}
      <AboutUs />

      <MissionAndVision />

      {/* Contact Us Section */}
      <ContactUs />
    </>
  );
}
