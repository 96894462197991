import { Link } from "react-router-dom";

export default function NotFoundPage() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-[#F7FAFC] text-gray-800">
      <h1 className="text-6xl font-bold text-[#336DBC]">404</h1>
      <p className="text-2xl font-medium text-gray-600 mt-4">
        Oops! The page you're looking for doesn't exist.
      </p>
      <Link
        to="/home"
        className="mt-6 bg-[#336DBC] text-white px-6 py-3 rounded-lg shadow-md hover:bg-[#285a9c] transition"
      >
        Back to Home
      </Link>
    </div>
  );
}
