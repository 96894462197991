import { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function ServicesComponent() {
  const [state, setState] = useState<number>(1);

  const auditsList = [
    {
      id: 1,
      name: "GCP Audits",
      path: "gcpAudits",
      currentLoc: "/services/gcpAudits",
      meta: {
        title: "GCP Audits - PharmaVigilence",
        description:
          "Ensure compliance with our GCP auditing services, tailored for clinical trials.",
        keywords: "GCP audits, compliance, clinical trials, audits",
      },
    },
    {
      id: 2,
      name: "GMP Audits",
      path: "gmpAudits",
      currentLoc: "/services/gmpAudits",
      meta: {
        title: "GMP Audits - PharmaVigilence",
        description:
          "Comprehensive GMP audits to ensure manufacturing compliance and quality control.",
        keywords: "GMP audits, manufacturing compliance, quality control",
      },
    },
    {
      id: 3,
      name: "GLP Audits",
      path: "glpAudits",
      currentLoc: "/services/glpAudits",
      meta: {
        title: "GLP Audits - PharmaVigilence",
        description:
          "Specialized GLP audits for ensuring laboratory compliance and data integrity.",
        keywords: "GLP audits, laboratory compliance, data integrity",
      },
    },
  ];

  // Get meta data for the selected audit
  const currentMeta = auditsList.find((audit) => audit.id === state)?.meta || {
    title: "Services - PharmaVigilence",
    description:
      "Discover our range of auditing services including GCP, GMP, and GLP audits.",
    keywords: "services, audits, GCP audits, GMP audits, GLP audits",
  };

  return (
    <>
      <Helmet>
        <title>{currentMeta.title}</title>
        <meta name="description" content={currentMeta.description} />
        <meta name="keywords" content={currentMeta.keywords} />
        <meta property="og:title" content={currentMeta.title} />
        <meta property="og:description" content={currentMeta.description} />
        <meta
          property="og:url"
          content={`https://pharmavigilence.com/services`}
        />
        <meta
          property="og:image"
          content="https://pharmavigilence.com/path-to-image.jpg"
        />
      </Helmet>

      <main className="flex flex-col gap-10 overflow-y-auto overflow-x-hidden bg-[#F7FAFC] py-10">
        {/* Header Section */}
        <section className="flex flex-col items-center justify-center text-center">
          <h1 className="text-4xl font-extrabold text-[#336DBC] mb-3">
            Our Services
          </h1>
          <p className="text-lg text-gray-600 max-w-3xl">
            Explore our comprehensive range of auditing services designed to
            meet your compliance and quality needs.
          </p>
        </section>

        {/* Navigation Tabs */}
        <section className="flex items-center justify-center">
          <ul className="flex justify-between items-center w-[70%] font-poppins font-semibold [&>*]:tracking-wide [&>*]:p-1 [&>*]:py-1.5 [&>*]:px-5 shadow-lg rounded-lg bg-white">
            {auditsList.map((audit) => (
              <NavLink
                to={audit.path}
                onClick={() => {
                  setState(audit.id);
                }}
                key={audit.id}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive || state === audit.id
                    ? "bg-[#E8F5F3] text-[#336DBC] w-full text-center border-b-4 border-[#336DBC] rounded-lg py-3"
                    : "w-full text-center text-gray-500 border-b-4 border-transparent hover:border-gray-300 rounded-lg py-3 transition-all duration-200"
                }
              >
                {audit.name}
              </NavLink>
            ))}
          </ul>
        </section>

        {/* Outlet for Child Routes */}
        <section className="px-10">
          <Outlet />
        </section>
      </main>
    </>
  );
}
