//import { Helmet } from "react-helmet";

export default function AboutUs() {
  return (
    <>
      {/* <Helmet>
        <title>About Us - PharmaVigilence</title>
        <meta
          name="description"
          content="Learn about PharmaVigilence, a leading pharmaceutical consultancy specializing in quality monitoring, audit services, and GxP compliance solutions."
        />
        <meta
          name="keywords"
          content="About PharmaVigilence, pharmaceutical consultancy, quality monitoring, audit services, GxP compliance"
        />
        <meta property="og:title" content="About Us - PharmaVigilence" />
        <meta
          property="og:description"
          content="Discover how PharmaVigilence pioneers healthcare auditing to ensure superior compliance and care standards."
        />
        <meta property="og:url" content="https://pharmavigilence.com/about" />
        <meta property="og:image" content="/about_us_image.png" />
      </Helmet> */}

      <section className="py-16 bg-white text-gray-800">
        <div className="container mx-auto px-5 md:px-20">
          <header className="text-center mb-10">
            <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900">
              About Us
            </h2>
            <p className="text-lg text-gray-600 mt-4">
              Pioneering Precision in Healthcare Auditing for Superior Compliance
              and Care
            </p>
          </header>
          <div className="flex flex-col md:flex-row gap-10 items-center">
            <div className="md:w-1/2">
              <img
                src="/home_about_us.svg"
                alt="About Us"
                className="w-full rounded-lg "
              />
            </div>
            <div className="md:w-1/2 space-y-6">
              <p className="text-lg text-gray-600 leading-relaxed">
                PharmaVigilence is a well-established pharmaceutical consultancy
                service provider specializing in quality monitoring and audit
                services for clinical studies, CROs, contract manufacturing plants,
                and contract testing facilities. Since 2024, PharmaVigilence has
                demonstrated outstanding expertise and services to pharmaceutical
                companies. We are highly focused on GxP compliance service-oriented
                solutions.
              </p>
              <p className="text-lg text-gray-600 leading-relaxed">
                Our auditing reports and data undergo thorough examination by a team
                of internal experts to ensure accuracy, compliance, and improvement.
                This process is vital for maintaining the integrity of audit
                findings and achieving excellence in compliance.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
