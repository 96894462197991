import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

export default function NavbarComponent() {
  const [dropdown, setDropdown] = useState<boolean>(false);
  const navigate = useNavigate();

  const navItems = [
    { id: 1, name: "Home", path: "/home" },
    { id: 2, name: "Services", path: "/services" },
    { id: 3, name: "About", path: "/about-us" },
  ];

  const serviceItems = [
    { id: 1, name: "GCP Audits", path: "gcpAudits" },
    { id: 2, name: "GMP Audits", path: "gmpAudits" },
    { id: 3, name: "GLP Audits", path: "glpAudits" },
  ];

  return (
    <header className="flex justify-between items-center bg-white shadow-md py-4 px-6 lg:px-12 sticky top-0 z-50">
      {/* Logo Section */}
      <div
        className="flex items-center cursor-pointer"
        onClick={() => navigate("/home")}
      >
        <img
          className="h-32 w-auto"
          src="/KPV-LOGO.png"
          alt="Pharma Vigilence Logo"
        />
      </div>

      {/* Navigation Links */}
      <nav className="hidden md:flex space-x-10">
        {navItems.map((item) => (
          <li
            key={item.id}
            className="list-none relative"
            onMouseEnter={
              item.name === "Services" ? () => setDropdown(true) : undefined
            }
            onMouseLeave={
              item.name === "Services" ? () => setDropdown(false) : undefined
            }
          >
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "text-[#336DBC] font-semibold underline decoration-[3px] decoration-[#69D7C1] underline-offset-8"
                  : "text-gray-700 hover:text-[#336DBC] transition"
              }
              to={item.path}
            >
              {item.name}
            </NavLink>
            {item.name === "Services" && (
              <div
                className={`absolute mt-2 bg-white rounded-lg shadow-lg w-48 z-50 border border-gray-200 transition-opacity duration-200 ${
                  dropdown ? "opacity-100 visible" : "opacity-0 invisible"
                }`}
                onMouseEnter={() => setDropdown(true)}
                onMouseLeave={() => setDropdown(false)}
              >
                <ul className="flex flex-col divide-y divide-gray-200">
                  {serviceItems.map((service) => (
                    <li
                      key={service.id}
                      className="p-3 hover:bg-[#E8F5F3] cursor-pointer transition-all duration-200 ease-in-out"
                      onClick={() => {
                        navigate(`/services/${service.path}`);
                        setDropdown(false);
                      }}
                    >
                      <span className="text-gray-700 hover:text-[#336DBC] font-poppins font-medium">
                        {service.name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </li>
        ))}
      </nav>

      {/* Contact Button */}
      <button
        className="hidden md:block bg-[#336DBC] text-white font-semibold px-6 py-2 rounded-md hover:bg-[#285a9c] transition"
        onClick={() => navigate("/contact")}
      >
        Contact
      </button>

      {/* Mobile Menu Button */}
      <div className="md:hidden">
        <button className="text-[#336DBC] focus:outline-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>
    </header>
  );
}
