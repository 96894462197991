import { Link } from "react-router-dom";

export default function OurServices(): JSX.Element {
  return (
    <section className="flex flex-col items-center py-16 bg-[#F7FAFC] text-gray-800">
      <div className="text-center mb-10 px-5 md:px-20">
        <h1 className="font-poppins text-3xl md:text-4xl font-bold text-[#336DBC] underline decoration-[3px] decoration-[#69D7C1] underline-offset-8">
          Our Services
        </h1>
        <p className="font-poppins text-lg md:text-xl text-gray-600 mt-4 leading-relaxed">
          Our clinical audit services are designed to enhance the quality and safety of patient care. We offer a comprehensive range of
          services, including compliance audits, risk management, performance improvement, and clinical effectiveness assessments. By
          leveraging our expertise, healthcare providers can ensure regulatory adherence, mitigate risks, and continuously improve clinical
          practices. Let us help you achieve excellence in every aspect of patient care.
        </p>
      </div>

      <ul className="grid grid-cols-1 md:grid-cols-3 gap-10 px-5 md:px-20 w-full max-w-7xl">
        {/* GCP Service Card */}
        <li className="bg-white rounded-lg shadow-md hover:shadow-lg transition p-5 flex flex-col items-center">
          <img
            className="rounded-t-lg h-60 w-full object-cover"
            src="/gcp_img.webp"
            alt="GCP Audits"
          />
          <div className="flex flex-col items-center mt-5">
            <Link to="/services/gcpAudits" className="text-center">
              <h5 className="text-[#336DBC] text-2xl font-semibold">
                GCP
              </h5>
              <p className="text-gray-600 text-sm font-light">
                Good Clinical Practice
              </p>
              <h6 className="text-gray-700 text-lg font-medium mt-2">
                Audits
              </h6>
            </Link>
          </div>
        </li>

        {/* GMP Service Card */}
        <li className="bg-white rounded-lg shadow-md hover:shadow-lg transition p-5 flex flex-col items-center">
          <img
            className="rounded-t-lg h-60 w-full object-cover"
            src="/gmp_img.webp"
            alt="GMP Audits"
          />
          <div className="flex flex-col items-center mt-5">
            <Link to="/services/gmpAudits" className="text-center">
              <h5 className="text-[#336DBC] text-2xl font-semibold">
                GMP
              </h5>
              <p className="text-gray-600 text-sm font-light">
                Good Manufacturing Practice
              </p>
              <h6 className="text-gray-700 text-lg font-medium mt-2">
                Audits
              </h6>
            </Link>
          </div>
        </li>

        {/* GLP Service Card */}
        <li className="bg-white rounded-lg shadow-md hover:shadow-lg transition p-5 flex flex-col items-center">
          <img
            className="rounded-t-lg h-60 w-full object-cover"
            src="/glp_img.webp"
            alt="GLP Audits"
          />
          <div className="flex flex-col items-center mt-5">
            <Link to="/services/glpAudits" className="text-center">
              <h5 className="text-[#336DBC] text-2xl font-semibold">
                GLP
              </h5>
              <p className="text-gray-600 text-sm font-light">
                Good Laboratory Practice
              </p>
              <h6 className="text-gray-700 text-lg font-medium mt-2">
                Audits
              </h6>
            </Link>
          </div>
        </li>
      </ul>

      <button className="mt-10 bg-[#336DBC] text-white rounded-lg px-10 py-3 font-semibold shadow-md hover:bg-[#285a9c] transition">
        Learn More
      </button>
    </section>
  );
}
