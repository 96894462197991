export default function MissionAndVision() {
  return (
    <section className="py-16 bg-white text-gray-800">
      <div className="container mx-auto px-5 md:px-20">
        <div className="text-center mb-10">
          <h1 className="text-3xl md:text-4xl font-bold text-[#336DBC]">
            Mission & Vision
          </h1>
          <p className="text-lg text-gray-600 mt-2">
            Learn about our mission and vision to revolutionize the pharmaceutical and clinical audit industry.
          </p>
        </div>

        {/* Mission Section */}
        <div className="flex flex-col md:flex-row gap-10 items-center">
          <div className="md:w-1/2">
            <img
              src="/358252855_11437286.jpg"
              alt="Mission Illustration"
              className="w-full rounded-lg "
            />
          </div>
          <div className="md:w-1/2 space-y-6">
            <h2 className="text-2xl font-semibold text-[#336DBC] underline decoration-[3px] decoration-[#69D7C1] underline-offset-8">
              Our Mission
            </h2>
            <p className="text-gray-600 text-lg leading-relaxed">
              Our mission is to serve as a trusted pharma and clinical development partner for global pharmaceutical research and
              development by performing high-quality audits. Driven by high ethical standards and practices, we strive to be responsible
              corporate citizens dedicated to the betterment of society.
            </p>
            <p className="text-gray-600 text-lg leading-relaxed">
              To achieve this mission, we’ve built a strong foundation supported by a team of experts and young energetic professionals.
              Together, we aim to provide total customer satisfaction and achieve leadership in chosen markets, products, and services
              across the globe.
            </p>
          </div>
        </div>

        {/* Vision Section */}
        <div className="flex flex-col md:flex-row-reverse gap-10 items-center mt-16">
          <div className="md:w-1/2">
            <img
              src="/7964380_3810090.jpg"
              alt="Vision Illustration"
              className="w-full rounded-lg "
            />
          </div>
          <div className="md:w-1/2 space-y-6">
            <h2 className="text-2xl font-semibold text-[#336DBC] underline decoration-[3px] decoration-[#69D7C1] underline-offset-8">
              Our Vision
            </h2>
            <p className="text-gray-600 text-lg leading-relaxed">
              Our vision is to be a leading pharmaceutical audit service company in India and become a significant global player by
              providing high-quality, affordable, and innovative solutions. We strive to make a mark both locally and internationally
              through excellence in the pharmaceutical industry.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
